#header {
    position: sticky;
    top: 0;
    z-index: 10;
    #nav-meta {
        --bs-body-font-size: #{px-to-rem(13px)};
        * {
            font-size: var(--bs-body-font-size);
        }
    }
    #nav-main {
        --bs-body-font-size: #{px-to-rem(15px)};
        * {
            font-size: var(--bs-body-font-size);
        }
    }
}
