h1,
.h1 {
    font-size: clamp(px-to-rem(28px), 2vw + 1.2rem, px-to-rem(40px));
    line-height: 1.4;
    text-wrap: balance;
}

h2,
.h2 {
    font-size: clamp(px-to-rem(22px), 2vw + 0.3rem, px-to-rem(30px));
    line-height: 1.4;
    text-wrap: balance;

    #hero & {
        text-shadow: $text-shadow;
    }
}

h3,
.h3 {
    font-size: clamp(px-to-rem(20px), 2vw, px-to-rem(28px));
    line-height: 1.4;
    text-wrap: balance;
}

h4,
.h4 {
    font-size: clamp(px-to-rem(18px), 2vw, px-to-rem(24px));
    line-height: 1.4;
    text-wrap: balance;
}
