@use "sass:math";

.frame-type-theme_sujet_teaser {
    --grid-rows: auto 1fr;
    --grid-row-content: 1/1;
    --grid-row-image: 2/2;

    --gradient1-display: none;
    --gradient1-position: circle at top;
    --gradient1-width: #{percentage(math.div(12, 12))};
    --gradient1-inset: #{$grid-gutter-width * -4} 0 0 0;

    --gradient2-display: none;
    --gradient3-display: none;

    --content-width: #{percentage(math.div(12, 12))};

    @include media-breakpoint-up(md) {
        --grid-rows: 1fr;
        --grid-row-image: 1/1;

        --gradient1-display: block;
        --gradient1-position: at center;
        --gradient1-width: #{percentage(math.div(8, 12))};
        --gradient1-inset: #{$grid-gutter-width * -2};

        --gradient2-display: block;

        --content-width: #{percentage(math.div(6, 12))};
    }

    @include media-breakpoint-up(ultrawide) {
        --gradient3-display: block;
    }

    .grid-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: var(--grid-rows);

        .image,
        .content {
            grid-column: 1/1;
        }

        .content {
            width: var(--content-width);
            z-index: 2;
            grid-row: var(--grid-row-content);
        }

        .image {
            z-index: 0;
            justify-self: end;
            grid-row: var(--grid-row-image);
            position: relative;

            &:before {
                display: var(--gradient2-display);
                content: "";
                position: absolute;
                top: $grid-gutter-width * -1;
                left: $grid-gutter-width * -1;
                bottom: $grid-gutter-width * -1;
                width: 30%;
                background: radial-gradient(
                        ellipse at top left,
                        #{__get($theme-colors, "action-4")} 25%,
                        transparent 70%
                    ),
                    radial-gradient(
                        ellipse at bottom left,
                        #{__get($theme-colors, "action-4")} 25%,
                        transparent 70%
                    );
            }

            &:after {
                display: var(--gradient3-display);
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 20%;
                background: linear-gradient(
                    to left,
                    #{__get($theme-colors, "action-4")} 25%,
                    transparent 70%
                );
            }

            display: grid;
            grid-template-rows: auto;
            grid-template-columns: auto;

            &__item {
                grid-column: 1/1;
                grid-row: 1/1;
                z-index: -1;

                @include after-first(1) {
                    animation: fadeInOut 10s infinite;
                    animation-delay: 10s;
                    opacity: 0;

                    @media (prefers-reduced-motion: reduce) {
                        animation: none;
                    }
                }
            }
        }

        &:before {
            display: var(--gradient1-display);
            content: "";
            background: radial-gradient(
                var(--gradient1-position),
                #{$white} 20%,
                #{$white} 40%,
                transparent 70%
            );

            position: absolute;
            inset: var(--gradient1-inset);
            z-index: 1;
            width: var(--gradient1-width);
        }
    }

    .content {
        ul {
            @extend .list-unstyled,
                .d-flex,
                .flex-wrap,
                .flex-column,
                .flex-md-row,
                .justify-content-center,
                .justify-content-md-start,
                .m-0,
                .gap-hg;

            li a {
                --bs-btn-font-size: 1rem;
            }
        }

        .btn-link {
            @extend .internal-link;
            text-decoration: none;
        }
    }
}
