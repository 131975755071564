:root {
    --supi-primary: var(--bs-secondary);
    --supi-secondary: var(--bs-primary);
    --supi-copytext: rgba(var(--bs-body-color-rgb), 1);
    --supi-light: rgba(var(--bs-white-rgb), 1);
    --supi-success: green;

    --supi-base-font-size: 16px;
    --supi-base-font-family: #{$font-family-sans-serif};
    --supi-base-font-weight: 400;
    --supi-base-color: var(--supi-copytext);
    --supi-base-spacer-bottom: 1em;

    --supi-border: 1px solid var(--supi-light);
    --supi-border-radius: 0.25em;
    --supi-box-shadow: 0 0.25em 1.25em rgba(0, 0, 0, 0.25);

    --supi-headings-font-family: #{$font-family-sans-serif};
    --supi-headings-font-weight: 400;
    --supi-headings-font-size: 1.5em;
    --supi-headings-font-color: var(--supi-copytext);
    --supi-headings-margin: 0 0 0.25em 0;

    --supi-overlay-background: #{rgba(__get($theme-colors, "white"), 0.8)};
    --supi-overlay-z-index: 999;
    --supi-overlay-padding: 1em;

    --supi-banner-background: #{var(--supi-light)};
    --supi-banner-padding: 1.5em;
    --supi-banner-box-shadow: 0 0.25em 1.25em #{rgba(
            __get($theme-colors, "copytext"),
            0.25
        )};
    --supi-banner-border-radius: 0;
    --supi-banner-border: none;

    --supi-button-padding: 0.5em 1.25em;
    --supi-button-border-radius: 0;
    --supi-button-border: 2px solid var(--supi-primary);
    --supi-button-background: var(--supi-primary);
    --supi-button-color: #{var(--supi-light)};
    --supi-button-font-family: var(--supi-headings-font-family);

    --supi-button-hover-background: #203a64;
    --supi-button-hover-color: #{var(--supi-light)};
    --supi-button-hover-border: 2px solid #203a64;

    --supi-button-expand-color: var(--supi-copytext);

    --supi-block-header-border: 1px solid var(--supi-copytext);
    --supi-block-header-padding: 0 0 0.25em 0;

    --supi-block-label-font-size: 1.25em;
    --supi-block-label-margin: 0;

    --supi-checkbox-keyhole: var(--supi-success);
    --supi-checkbox-checked-background: var(--supi-success);
    --supi-checkbox-unchecked-background: rgba(var(--bs-secondary-rgb), 0.5);

    --supi-table-heading-font-family: var(--supi-headings-font-family);
    --supi-table-background: #{$sand};
    --supi-table-border: #{$sand};
    --supi-table-box-shadow: 0px 0px 2px rgba(var(--bs-body-color-rgb), 0.25);

    --supi-yt-column: 1;
    --supi-yt-row: 1;
    --supi-yt-overlay-background: rgba(0, 0, 0, 0.8);
    --supi-yt-overlay-color: #{__get($theme-colors, "white")};
    --supi-yt-overlay-padding: 1.5em;
}

// banner and buttons
#supi {
    font-size: var(--supi-base-font-size, 16px);
    font-family: var(--supi-base-font-family, "Helvetica, Arial, sans-serif");
    font-weight: var(--supi-base-font-weight, 400);
    color: var(--supi-base-color);

    .sr-only {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }

    button {
        &:not([aria-expanded]) {
            color: var(--supi-button-color, var(--supi-base-color));
            background: var(--supi-button-background, #efefef);
            padding: var(--supi-button-padding, 1em 2em);
            border-radius: var(
                --supi-button-border-radius,
                var(--supi-border-radius)
            );
            border: var(--supi-button-border, var(--supi-border));
            margin: 0;
            font-family: var(
                --supi-button-font-family,
                var(--supi-base-font-family)
            );

            &:hover {
                background: var(
                    --supi-button-hover-background,
                    var(--supi-button-background)
                );
                color: var(--supi-button-hover-color, var(--supi-button-color));
                border: var(
                    --supi-button-hover-border,
                    var(--supi-button-border)
                );
            }

            &#supi__individualSwitchTo {
                --supi-button-border: 2px solid var(--supi-primary);
                --supi-button-background: transparent;
                --supi-button-color: var(--supi-primary);
            }
        }

        &[aria-expanded] {
            background: none;
            border: none;
            display: flex;
            align-items: center;
            gap: 0.5em;
            padding: 0;
            color: __get($theme-colors, "black");

            &:before {
                content: "";
                width: 0.5em;
                height: 0.5em;
                border-left: 2px solid var(--supi-button-expand-color);
                border-top: 2px solid var(--supi-button-expand-color);
                transform: rotate(135deg);
                transition: all 0.2s ease-in-out;
            }

            &[aria-expanded="true"]:before {
                transform: rotate(225deg);
                margin-top: -2px;
            }
        }
    }

    &__overlay {
        position: fixed;
        inset: 0;
        background: var(--supi-overlay-background);
        z-index: var(--supi-overlay-z-index, 999);
        padding: var(--supi-overlay-padding);
        display: grid;
        place-content: end center;

        @include media-breakpoint-up(md) {
            place-content: center;
            --supi-overlay-padding: 2em;
        }
    }

    &__banner {
        @include media-breakpoint-up(md) {
            width: 75vw;
            max-width: 900px;
        }

        @include media-breakpoint-up(lg) {
            min-width: 900px;
            --supi-banner-padding: 2em;
        }

        background: var(--supi-banner-background, var(--supi-light));
        padding: var(--supi-banner-padding, 1em);
        box-shadow: var(--supi-banner-box-shadow, var(--supi-box-shadow));
        border-radius: var(
            --supi-banner-border-radius,
            var(--supi-border-radius)
        );
        border: var(--supi-banner-border, var(--supi-border));
        overflow-y: auto;

        &:focus-visible {
            outline: none;
        }
    }

    &__label,
    &__label_detail {
        font-family: var(
            --supi-headings-font-family,
            "Helvetica, Arial, sans-serif"
        );
        font-weight: var(--supi-headings-font-weight, 700);
        font-size: var(--supi-headings-font-size, 2em);
        color: var(--supi-headings-font-color, var(--supi-base-color));
        margin: var(--supi-headings-margin, 0 0 1em 0);
    }

    &__detailview {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }

    .tx-supi {
        &-overlay {
            &.hide {
                display: none !important;
            }
        }

        &__pane {
            &-visible {
                display: flex;
                flex-direction: column;
                gap: 1em;
            }

            &-hidden {
                display: none !important;
            }
        }

        &__buttongroup {
            ul {
                padding: 0;
                margin: 0;
                display: flex;
                list-style: none;
                justify-content: space-between;

                flex-direction: column;
                gap: 0.75em;

                button {
                    width: 100%;
                }

                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                    gap: 0;

                    button {
                        width: auto;
                    }
                }
            }
        }

        &__label {
            font-size: var(
                --supi-block-label-font-size,
                var(--supi-headings-font-size)
            );
            font-family: var(
                --supi-block-label-font-family,
                var(--supi-headings-font-family)
            );
            font-weight: var(
                --supi-block-label-font-weight,
                var(--supi-headings-font-weight)
            );
            margin: var(--supi-block-label-margin, var(--supi-headings-margin));
        }

        &__block {
            display: flex;
            flex-direction: column;
            gap: 1em;

            &-header {
                display: flex;
                justify-content: space-between;
                border-bottom: var(--supi-block-header-border, 1px solid #000);
                padding: var(--supi-block-header-padding, 0 0 0.5em 0);
            }

            &-body {
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    gap: 0.25em;
                }
            }

            &-item {
                &__header {
                    display: flex;
                    justify-content: space-between;
                }

                &__table {
                    display: flex;
                    flex-direction: column;
                    margin: 1em 0 1em 1em;
                    font-size: 0.875em;
                    gap: 1em;

                    a {
                        color: inherit;
                    }

                    table {
                        width: 100%;

                        @include media-breakpoint-up(md) {
                            margin: 0;
                        }
                    }

                    tbody {
                        display: flex;
                        flex-direction: column;
                        gap: 0.25em;
                    }

                    tr {
                        display: flex;
                        flex-direction: column;

                        @include media-breakpoint-up(md) {
                            flex-direction: row;
                            gap: 0.5em;
                        }
                    }

                    th {
                        font-family: var(--supi-table-heading-font-family);
                        flex: 0 0 20%;
                    }

                    th,
                    td {
                        @include media-breakpoint-up(md) {
                            padding: 0.25em 0;
                        }
                    }

                    &-cookie {
                        background: var(--supi-table-background);
                        border: 1px solid var(--supi-table-border);
                        box-shadow: var(--supi-table-box-shadow);

                        tbody {
                            padding: 0.5em;
                        }

                        th,
                        td {
                            @include media-breakpoint-up(md) {
                                padding: 0.25em 0.5em;
                            }
                        }
                    }
                }
            }
        }
    }

    .supi {
        &-checkbox {
            display: flex;
            align-items: center;
            position: relative;

            input {
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                opacity: 0;
                position: absolute;

                &:checked {
                    & + label {
                        background: var(
                            --supi-checkbox-checked-background,
                            var(--supi-success)
                        );
                        box-shadow:
                            inset 0 0 0 0 rgba(0, 0, 0, 0.2),
                            0 0 0 2px var(--supi-checkbox-checked-background);

                        &:before {
                            transform: translateX(20px);
                            box-shadow:
                                1px 1px rgba(0, 0, 0, 0.2),
                                0 0 0 2px
                                    var(--supi-checkbox-checked-background);
                        }
                    }

                    & + label svg {
                        transform: translate(-50%, -50%);
                        transition: 0.1s ease-in-out;
                    }
                }

                &:disabled {
                    & + label {
                        opacity: 0.6;
                    }

                    & + label svg {
                        opacity: 1;
                    }
                }
            }

            label {
                display: inline-block;
                position: relative;
                cursor: pointer;
                width: 40px;
                height: 20px;
                border-radius: 20px;
                background: var(--supi-checkbox-unchecked-background);
                box-shadow:
                    inset 0 0 0 0 rgba(0, 0, 0, 0.2),
                    0 0 0 2px var(--supi-checkbox-unchecked-background);
                transition: 0.1s ease-in-out;
                margin: 0;
                color: var(--supi-checkbox-keyhole);

                &:before {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    background: var(--supi-light);
                    box-shadow:
                        1px 1px rgba(0, 0, 0, 0.2),
                        0 0 0 2px var(--supi-checkbox-unchecked-background);
                    transition: 0.1s ease-in-out;
                }
            }

            svg {
                position: absolute;
                display: block;
                opacity: 0;
                top: 50%;
                right: 4px;
                transform: translate(-400%, -50%);
                transition: 0.1s ease-in-out;
            }
        }
    }
}

// content elements
.tx-supi {
    &__service {
    }
}
