.separator {
    li {
        display: flex;
        gap: inherit;

        &:after {
            content: "";
            width: 1px;
            height: 100%;
            background: $body-color;
        }

        &:last-child:after {
            display: none;
        }
    }
}
