.icon {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @each $icon, $size in $icon-sizes {
            @if $breakpoint == "xs" {
                &-#{$icon} {
                    width: $size;
                    height: $size;
                    flex: 0 0 $size;
                }
            } @else {
                &-#{$breakpoint}-#{$icon} {
                    @include media-breakpoint-up($breakpoint) {
                        width: $size;
                        height: $size;
                        flex: 0 0 $size;
                    }
                }
            }
        }
    }

    &-right {
        transform: rotate(180deg);
    }
}
