$cyan: #74dae7;
$blue: #28487d;
$purple: #6e6e9b;
$sand: #f2f1ef;

$gray-100: #fafafa;
$gray-200: #f9f9f9;
$gray-300: #f3f2f0;
$gray-400: #e8e8e8;
$gray-500: #939393;
$gray-700: #737373;

$body-color: #3e4355;

$primary: $cyan;
$secondary: $blue;

$link-color: $secondary;
$nav-color: #040041;
