@use "sass:math";

.hamburger {
    display: block;
    width: 24px;
    aspect-ratio: 1 / 1;
    position: relative;
    color: __get($theme-colors, "secondary");

    .bread,
    .patty {
        --offset: -1px;
        --rotation: 0;
        --width: 100%;

        display: block;
        position: absolute;
        width: var(--width);
        height: 2px;
        background: currentColor;
        transform-origin: center center;
        top: 50%;
        left: 50%;
        transform: translateY(var(--offset)) translateX(-50%)
            rotateZ(var(--rotation));
    }

    .bread {
        &--top {
            margin-top: -30%;
        }

        &--bottom {
            margin-top: 30%;
        }
    }

    [aria-expanded="true"] &,
    &--active#{&} {
        .bread {
            --offset: 0;
            --width: #{math.div(1, math.cos(45deg)) * 100%};

            margin-top: 0;

            &--top {
                --rotation: 45deg;
            }

            &--bottom {
                --rotation: -45deg;
            }
        }

        .patty {
            opacity: 0;
        }
    }
}
