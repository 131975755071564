#hero {
    &:after {
        content: "";
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            90deg,
            $purple-500 0%,
            $purple-400 50%,
            $purple-500 100%
        );
    }
    &.has-teaser:after {
        height: 75%;
    }
}
