:root {
    --internal-link-chevron: #{svg("chevron", $link-color)};
}

.internal-link {
    display: inline-flex;
    gap: $grid-gutter-width * 0.25;

    &:before {
        content: "";
        width: 9px;
        height: 13px;
        align-self: center;
        background-image: var(--internal-link-chevron);
        background-repeat: no-repeat;
        background-position: center top;
        transform: rotate(var(--internal-link-rotate, 0));
    }
}
