// load all variables
@import "breakpoints";
@import "container";
@import "aspect-ratios";
@import "animations";

// fonts
$font-family-sans-serif:
    "Droid Sans",
    system-ui,
    -apple-system,
    "Helvetica Neue",
    Arial,
    sans-serif;

$text-shadow: 1px 1px 1px rgba(var(--bs-black-rgb), 0.5);

$grid-gutter-width: 2rem;
$enable-negative-margins: true;

// use this map, if you need to use inline svg in css styles.
// use the svg() function to get a specified icon with a specific
// color
// e.g. app/packages/theme/Build/Src/Scss/Atoms/internal-link.scss
$icons: (
    "chevron":
        'data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5L7 6.5L1 11.5" stroke="$color" stroke-width="2"/></svg>',
    "chevron-nav":
        'data:image/svg+xml,%3Csvg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.6017 6.45835L8.62986 12.1802C8.42986 12.349 8.24236 12.4177 8.08299 12.4177C7.92361 12.4177 7.70892 12.3484 7.56455 12.2087L1.56455 6.45835C1.26467 6.17397 1.25492 5.67085 1.54205 5.39897C1.82721 5.09819 2.30392 5.08841 2.60174 5.37652L8.08299 10.6302L13.5642 5.38022C13.8611 5.09213 14.3386 5.10191 14.6239 5.40268C14.9111 5.67085 14.9017 6.17397 14.6017 6.45835Z" fill="$color" /%3E%3C/svg%3E%0A',
);

// icons
$icon-sizes: (
    10: 10px,
    16: 16px,
    22: 22px,
    32: 32px,
    40: 40px,
);

$pagination-border-width: 0;
$pagination-focus-bg: transparent;
