body {
    height: 100vh;
    display: flex;
    flex-direction: column;

    & > header,
    & > footer {
        flex: 0 auto;
    }

    & > main {
        flex: 1 0 auto;
    }

    &.nav-open {
        overflow-y: hidden;
    }
}
