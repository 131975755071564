@use "sass:math";

:root {
    --news-list-item-width: calc(
        #{percentage(math.div(12, 12))} - #{$grid-gutter-width}
    );
}

.news {
    &-list {
        &__startpage {
            &-item {
                flex: 1 0 calc(50% - #{$grid-gutter-width * 0.5});
            }
        }

        &__list {
            &-item {
                flex: 1 0 var(--news-list-item-width);
                @include hover;

                &--top {
                    @include media-breakpoint-up(xl) {
                        --news-list-item-width: calc(
                            #{percentage(math.div(6, 12))} - #{$grid-gutter-width}
                        );
                    }
                }

                &--image {
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    &-detail {
        --internal-link-rotate: 180deg;

        &__teaser {
            font-style: italic;
            color: $gray-700;
            font-size: px-to-rem(20px);
        }
    }
}
