.image-teaser {
    &__wrapper {
        grid-template-columns: 1fr;
        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .content,
    picture {
        grid-column: 1;
        grid-row: 1;
    }

    .content {
        width: 65%;
        .subheader {
            margin-bottom: $grid-gutter-width * 0.25;
        }

        .bodytext a {
            @extend .internal-link;
            &:before {
                margin-top: 5px;
                align-self: unset;
            }
        }
    }

    &:nth-child(1) {
        order: 1;
        picture img {
            object-position: center;
        }
    }

    &:nth-child(2) {
        order: 3;
        picture img {
            object-position: right center;
        }
    }

    &:nth-child(3) {
        order: 2;
    }

    &:nth-child(4) {
        order: 4;
    }
}
