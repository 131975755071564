@import "../../../node_modules/glightbox/dist/css/glightbox";

.goverlay {
    background-color: __get($theme-colors, "white");
    .glightbox-mobile & {
        background: __get($theme-colors, "white");
    }
    .gslide-description {
        background-color: transparent;
        .gdesc-inner {
            padding-top: $grid-gutter-width * 0.5;
            .gslide-desc {
                color: $black;
            }
        }
    }
}

.glightbox-mobile .glightbox-container .gslide-description {
    flex: 0 0 auto;
    padding: 0;
}

.gcontainer {
    .gslide-media {
        box-shadow: none;
        @include media-breakpoint-down(lg) {
            img {
                max-height: 75vh;
            }
        }
    }
    .gslide-description {
        background: transparent !important;
        position: relative !important;
        flex: 0;
        .gdesc-inner {
            padding-top: $grid-gutter-width * 0.5;
            .gslide-desc {
                text-align: center;
                color: $gray-700;
            }
        }
    }
    .gbtn {
        &.gclose {
            opacity: 1;
            top: 30px;
            right: 30px;
        }

        &.gnext,
        &.gprev {
            top: auto;
            bottom: 30px;
            @include media-breakpoint-up(md) {
                top: 50%;
                bottom: auto;
                transform: translateY(-50%);
            }
            &.disabled {
                display: none;
            }
        }
    }
}
