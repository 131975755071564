.search-form {
    &__input {
        grid-row: 1;
        grid-column: 1;
        border: 1px solid $gray-500;
        background-color: $gray-100;
        ::placeholder {
            color: $gray-700;
        }
    }

    &__button {
        grid-column: 1;
        grid-row: 1;
        display: block;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        height: 100%;
        aspect-ratio: 1 / 1;
        color: inherit;
        justify-self: end;
    }

    div {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: __get($colors, "white");
        z-index: 1;
        box-shadow: $box-shadow;

        ul {
            list-style: none;
            padding: $grid-gutter-width * 0.5 0;
            margin: 0;

            li {
                display: flex;

                a {
                    width: 100%;
                    padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
                    color: __get($theme-colors, "copytext");
                    text-decoration: none;

                    &.hover,
                    &:hover {
                        background: __get($theme-colors, "action-3");
                    }
                }

                mark {
                    background-color: transparent;
                    font-weight: bold;
                    padding: 0;
                    color: __get($colors, "black");
                }
            }
        }
    }
}

.results {
    &-list {
        list-style: none;
    }

    &-entry {
        border-top: 1px solid $gray-400;

        @include hover;
    }
}
