.navigation {
    &__toggle-menu,
    &__toggle-search {
        display: flex;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        height: 100%;
        aspect-ratio: 1 / 1;
        color: inherit;
        width: 45px;
        justify-content: center;
        align-items: center;
    }

    &__wrapper {
        box-shadow: $box-shadow;
        height: calc(100vh - var(--main-navigation-height-mobile));
        overflow-y: auto;

        @include media-breakpoint-up(xl) {
            box-shadow: none;
            height: auto;
            overflow: unset;
            > div {
                gap: $grid-gutter-width * 0.5;
            }
        }

        .nav-item {
            .nav-link {
                @include media-breakpoint-down(xl) {
                    padding-top: $grid-gutter-width * 0.5;
                    padding-bottom: $grid-gutter-width * 0.5;
                    border-bottom: 1px solid $gray-400;
                    border-top: none;
                }

                &.active {
                    font-weight: bold;
                }

                &.dropdown-toggle {
                    display: flex;
                    align-items: center;
                    &:after {
                        background-image: svg("chevron-nav", $secondary);
                        background-repeat: no-repeat;
                        background-position: center top;
                        border: none;
                        width: 16px;
                        height: 16px;
                        margin-left: $grid-gutter-width * 0.25;
                        transform: rotate(0);
                        transition: transform 0.1s ease;
                    }
                    &.show {
                        &:after {
                            transform: rotate(180deg);
                            @include media-breakpoint-up(xl) {
                                transform: rotate(0);
                            }
                        }
                    }

                    &.show {
                        @extend .shadow;

                        &:before {
                            content: "";
                            position: absolute;
                            bottom: -5px;
                            left: 0;
                            width: 100%;
                            height: 10px;
                            background-color: __get($colors, "white");
                            z-index: 9999;
                        }
                    }
                }
            }

            &.dropdown {
                @include last(3) {
                    .dropdown-menu {
                        right: 0;
                        left: auto;
                    }
                }
            }

            .dropdown-menu {
                padding-top: px-to-rem(22px);
                padding-bottom: px-to-rem(29px);

                .nav-item {
                    .dropdown-item {
                        padding-top: px_to-rem(12px);
                        padding-bottom: px_to-rem(12px);
                        border-bottom: 1px solid $gray-400;
                        @include media-breakpoint-down(xl) {
                            white-space: break-spaces;
                        }
                        &.active {
                            font-weight: bold;
                        }
                    }

                    &:first-child {
                        .dropdown-item {
                            border-top: 1px solid $gray-400;
                        }
                    }
                }

                @include media-breakpoint-down(xl) {
                    padding: 0;
                }

                @include media-breakpoint-up(xl) {
                    box-shadow: $box-shadow;
                    max-height: calc(100vh - var(--main-navigation-height));
                    overflow-y: auto;
                }
            }
        }

        @include media-breakpoint-down(xl) {
            display: none;
            background: __get($colors, "white");
            top: var(--main-navigation-height-mobile);
            position: absolute;
            left: 0;
            width: 100%;

            .nav-open & {
                display: block;
            }

            .navbar .nav {
                flex-direction: column;
                width: 100%;

                .nav-item {
                    .nav-link {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .dropdown-menu {
                        position: relative;
                    }
                }
            }

            .navbar {
                padding: 0;
            }
        }

        .nav-meta .nav {
            @include media-breakpoint-down(xl) {
                .nav-link {
                    color: $body-color;
                }
            }

            @include media-breakpoint-up(xl) {
                align-items: center;
                .nav-item {
                    &:not(:last-child):not(:nth-last-child(2)) {
                        border-right: 1px solid
                            __get($theme-colors, "secondary");
                    }
                }
            }
        }
    }

    .logo svg {
        width: 104px;
        height: 61px;

        @include media-breakpoint-up(xl) {
            width: 155px;
            height: 89px;
        }

        @include media-breakpoint-up(xxl) {
            width: 215px;
            height: 123px;
        }
    }

    &__search {
        @include media-breakpoint-down(xl) {
            display: none;
            background: __get($colors, "white");
            top: var(--main-navigation-height-mobile);
            position: absolute;
            left: 0;
            width: 100%;

            .search-open & {
                display: block;
            }
        }
    }
}
