.frame:not(.frame-type-theme_sujet_teaser) {
    margin-bottom: $grid-gutter-width;

    @include media-breakpoint-up(md) {
        margin-bottom: $grid-gutter-width * 2;
    }

    #footer & {
        margin-bottom: 0;
    }
}
