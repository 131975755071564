.bodytext {
    .frame-type-textpic:has(figure) & {
        margin-bottom: $grid-gutter-width * 0.5;
    }

    p {
        .frame-type-theme_sujet_teaser & {
            margin-bottom: $grid-gutter-width;
        }
    }
}
