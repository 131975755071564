.teaserboxes {
    --bs-link-color-rgb: #{__get($theme-colors-rgb, "white")};
    --bs-link-hover-color-rgb: #{__get($theme-colors-rgb, "white")};
    --internal-link-chevron: #{svg("chevron", $white)};

    * {
        text-shadow: $text-shadow;
    }

    svg,
    .internal-link:before {
        filter: drop-shadow($text-shadow);
    }

    &__box {
        &-list {
            &-item {
                font-size: px-to-rem(14px);
                padding-left: $grid-gutter-width * 0.5;

                &:first-of-type {
                    font-size: px-to-rem(22px);
                    padding-left: 0;
                }
            }
        }

        &-link {
            text-decoration: none;
            text-underline-offset: 6px;

            &:hover {
                text-decoration: underline;
                --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
            }
        }

        &-revenue {
            &__line {
                &1 {
                    font-size: px-to-rem(40px);
                }
            }
        }
    }
}
