@use "sass:math";

.footer-columns {
    h2 {
        color: $secondary;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: $grid-gutter-width * 0.5;
    }

    .col {
        &:first-of-type ul {
            flex-direction: column;
        }

        &:last-of-type ul {
            @extend .list-unstyled,
                .m-0,
                .d-flex,
                .flex-column,
                .flex-md-row,
                .column-gap-hg,
                .row-gap-sg,
                .flex-wrap;

            li {
                flex: 1 0 calc(50% - #{$grid-gutter-width * 0.5});

                @include media-breakpoint-up(md) {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }

    a {
        text-decoration: none;
    }
}
