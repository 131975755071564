@use "sass:math";

:root {
    --gallery-width: 100%;

    @include media-breakpoint-up(md) {
        --gallery-width: calc(
            #{percentage(math.div(6, 12))} - #{$grid-gutter-width * 0.5}
        );
    }

    @include media-breakpoint-up(xl) {
        --gallery-width: calc(
            #{percentage(math.div(4, 12))} - #{$grid-gutter-width * 0.5}
        );
    }

    @include media-breakpoint-up(xxl) {
        --gallery-width: calc(
            #{percentage(math.div(3, 12))} - #{$grid-gutter-width * 0.5}
        );
    }
}

.frame-type-image,
.frame-type-textpic,
.frame-type-textmedia {
    // firefucks fallback *sigh*
    figure {
        flex: 0 var(--gallery-width);
    }

    // more than one image = gallery *sigh*
    &:has(figure:nth-last-child(n + 2)) {
        figure {
            flex: 0 var(--gallery-width);
        }
    }
}
