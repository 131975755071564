.pagination {
    .page-item:not(.current) {
        --bs-pagination-bg: transparent;
    }

    .page-link:hover {
        --bs-pagination-hover-bg: transparent;
    }

    .current .page-link:hover {
        --bs-pagination-hover-bg: #{$white};
    }

    .page-item:not(.previous):not(.next):not(.current) {
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
        }
    }
}
