ul {
    &.list-style {
        &-chevron {
            li {
                position: relative;
                padding-left: $grid-gutter-width * 0.75;

                &:before {
                    content: "";
                    width: 9px;
                    height: 13px;
                    align-self: center;
                    background-image: var(--internal-link-chevron);
                    background-repeat: no-repeat;
                    background-position: center top;
                    position: absolute;
                    left: 0;
                    top: $grid-gutter-width * 0.125;
                }
            }
        }
    }
}
