#nav-breadcrumbs {
    .separator li {
        &:after {
            display: none;
        }

        @include media-breakpoint-up(md) {
            &:not(:first-child):not(:last-child) {
                &:after {
                    display: block;
                }
            }
        }
    }

    li {
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
        }

        @include from-first-last(2) {
            display: flex;
        }

        @include from-end(2) {
            gap: $grid-gutter-width * 0.25;

            @include media-breakpoint-up(md) {
                gap: inherit;
            }

            &:before {
                content: "";
                width: 9px;
                height: 13px;
                align-self: center;
                background-image: var(--internal-link-chevron);
                background-repeat: no-repeat;
                background-position: center top;
                transform: rotate(180deg);

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }
    }
}
