@font-face {
    font-display: swap;
    font-family: "Droid Sans";
    font-style: normal;
    font-weight: 400;
    src: url("../Webfonts/droid_sans_400.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: "Droid Sans";
    font-style: normal;
    font-weight: 700;
    src: url("../Webfonts/droid_sans_700.woff2") format("woff2");
}
